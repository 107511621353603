import './scss/index.scss';
import 'design-system-component-library/marketplaces.css';
import { createInertiaApp, Link, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createSSRApp, defineAsyncComponent, h } from 'vue';
import VueCookies from 'vue-cookies';
import { createPinia } from 'pinia';
import { addComponents } from '@utils/components';
import isDesktop from '../../js/utils/is-desktop';
import { setupDesignSystemComponentLibrary } from 'design-system-component-library';

let isFirstPageLoad = true;
router.on('navigate', event => {
    setTimeout(() => {
        window.dataLayer = window.dataLayer || [];
        const _paq = window._paq || [];
        window._paq = _paq;

        function gtag(...parameters) {
            window.dataLayer.push(...parameters);
        }

        if (!event.detail.page.props.settings.oneTrustEnabled) {
            const hasCookieConsent = window.$cookies.get('cookieConsent') === '1';
            const isCookiePage = event.detail.page.url === '/' || event.detail.page.url.includes('cookie');

            if (isFirstPageLoad) {
                gtag('js', new Date());
                gtag('consent', 'default', {
                    ad_storage: hasCookieConsent ? 'granted' : 'denied',
                    analytics_storage: hasCookieConsent ? 'granted' : 'denied',
                });

                if (hasCookieConsent) {
                    _paq.push([function () {
                        if (!this.hasRememberedConsent()) {
                            _paq.push(['rememberConsentGiven']);
                        }
                    }]);
                }
            } else if (!isCookiePage && !window.$cookies.isKey('cookieConsent')) {
                window.$cookies.set('cookieConsent', '1');

                gtag('consent', 'update', {
                    ad_storage: 'granted',
                    analytics_storage: 'granted',
                });

                _paq.push([function () {
                    if (!this.hasRememberedConsent()) {
                        _paq.push(['rememberConsentGiven']);
                    }
                }]);
            }
        }

        if (event.detail.page.props.settings.googleUa) {
            gtag('config', event.detail.page.props.settings.googleUa);
            gtag({ GoogleUA: event.detail.page.props.settings.googleUa });
        }

        gtag(function () {
            this.set('time', new Date());
        });

        gtag({
            event: 'Pageview',
            pagePath: window.location.pathname,
            pageTitle: window.document.title,
        });

        _paq.push(['setCustomUrl', window.location.pathname]);
        _paq.push(['setDocumentTitle', window.document.title]);
        _paq.push(['trackPageView']);

        isFirstPageLoad = false;
    });

    isDesktop();
});

createInertiaApp({
    resolve: name => {
        const [, component] = name.split('::');

        return resolvePageComponent(`./pages/${component}.vue`, import.meta.glob('./pages/**/*.vue', { eager: true }))
            .then(page => {
                page.default.layout = page.default.layout || defineAsyncComponent(() =>
                    import('./layouts/default-layout.vue'),
                );
                return page;
            });
    },
    setup: async ({ el, App, props, plugin }) => {
        const ssrWorking = el.childNodes.length > 0;
        if (!import.meta.env.DEV && !props.initialPage.props.isProduction && !ssrWorking) {
            const error = 'app.js - SSR not working?! Check the (docker) logs for any SSR error. Preventing the app from client-side rendering.';
            console.error(error);

            const errorElement = document.createElement('div');
            errorElement.innerHTML = `<h1><b>${error}</b></h1>`;
            el.appendChild(errorElement);

            throw error;
        }

        let app = createSSRApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueCookies)
            .use(createPinia())
            .component('Link', Link);

        app = await addComponents(props.initialPage.props.theme, app);

        await setupDesignSystemComponentLibrary('fr');

        app.mount(el);
    },
});
