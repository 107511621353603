<template>
    <header class="header-category-container">
        <div class="header-category">
            <div class="header-category__inner">
                <div class="header-category__header">
                    <span class="header-category__header-title u-heading-4">
                        {{ translations.categoryPageTitle }}
                    </span>
                    <h1 class="header-category__header-subtitle">
                        {{ title }}
                    </h1>
                </div>
                <div class="header-category__content">
                    <div class="header-category__text-container">
                        <!-- eslint-disable vue/no-v-html -->
                        <p class="header-category__text" v-html="text"></p>
                    </div>
                    <div
                        v-if="image"
                        class="header-category__image-container"
                    >
                        <WatsonAtomImage
                            :content="image"
                            class="header-category__image"
                            dimension="none"
                        />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';
import { usePage } from '@inertiajs/vue3';

defineProps({
    title: {
        type: String,
        required: false,
        default: '',
    },
    text: {
        type: String,
        required: false,
        default: '',
    },
    image: {
        type: Object,
        required: false,
        default: null,
    },
});

const page = usePage();
const translations = page.props.settings.translations.priceGuide;
</script>

<style lang="scss">
@use 'watson-theme-header-category';
</style>
