<template>
    <div class="product-graph">
        <div class="product-graph__national-average u-heading-4">
            {{ translations.nationalAverage }} {{ priceFormat(priceGuide.lowPrice) }} - {{ priceFormat(priceGuide.highPrice) }} {{ priceGuide.units }}
        </div>
        <div
            class="product-graph__information"
        >
            {{ priceGuide.legend ? `${translations.verifiedBy} ${priceGuide.legend}.` : translations.verifiedBy.replace(',', '.') }}
        </div>
        <div class="product-graph__graph">
            <WatsonThemeAssetProductGraph />

            <div class="product-graph__part">
                <div class="product-graph__part-label u-heading-4 u-regular">
                    {{ translations.lowEndPrice }}
                </div>

                <div class="product-graph__part-value u-heading-3">
                    {{ priceFormat(priceGuide.lowPrice) }}
                </div>
            </div>
            <div class="product-graph__part">
                <div class="product-graph__part-label u-heading-4 u-regular">
                    {{ translations.averagePrice }}
                </div>
                <div class="product-graph__part-value u-heading-3">
                    {{ priceFormat(averagePrice) }}
                </div>
            </div>
            <div class="product-graph__part">
                <div class="product-graph__part-label u-heading-4 u-regular">
                    {{ translations.highEndPrice }}
                </div>
                <div class="product-graph__part-value u-heading-3">
                    {{ priceFormat(priceGuide.highPrice) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

import WatsonThemeAssetProductGraph from '../../assets/watson-theme-asset-product-graph.vue';

const page = usePage();
const props = defineProps({
    priceGuide: {
        type: Object,
        required: true,
    },
});

const roundPrice = price => {
    if (price >= 10000) {
        return Math.floor(price / 100) * 100;
    } else if (price >= 100) {
        return Math.floor(price / 10) * 10;
    }
    return price;
};

const averagePrice = computed(() => {
    if (props.priceGuide.averagePrice) return props.priceGuide.averagePrice;

    const calculated = parseInt(props.priceGuide.lowPrice) * 0.7 + parseInt(props.priceGuide.highPrice) * 0.3;
    return roundPrice(calculated);
});

const priceFormat = price => {
    // TODO: After migration - Use the correct locale and currency based on site settings.
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
    }).format(Math.round(price));
};

const translations = page.props.settings?.translations?.priceGuide;
</script>

<style lang="scss">
@use 'watson-theme-product-graph';
</style>
