<template>
    <section class="article-header-container">
        <div class="article-header">
            <Component
                :is="headingTag"
                class="article-header__title"
            >
                {{ decodeHtmlEntities(pageSettings.title || pageSettings.editorTitle || '') }}
            </Component>

            <WatsonThemeAuthorBlock
                v-if="authors.writer"
                :content="authors"
            />

            <!-- eslint-disable vue/no-v-html -->
            <div
                class="article-header__description"
                v-html="decodeHtmlEntities(pageSettings.description || pageSettings.editorDescription || '')"
            ></div>

            <figure
                v-if="pageSettings.featuredImage && !disabledImage"
                class="article-header__figure"
            >
                <WatsonAtomImage
                    :content="pageSettings.featuredImage"
                    class="article-header__image"
                    dimension="none"
                />

                <figcaption
                    v-if="pageSettings.featuredImage.caption"
                    class="article-header__caption u-tiny-text"
                >
                    {{ stripHtml(pageSettings.featuredImage.caption) }}
                </figcaption>
            </figure>
        </div>
    </section>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import stripHtml from '@utils/strip-html';

import WatsonAtomImage from '../../../../components/atoms/image/watson-atom-image.vue';
import WatsonThemeAuthorBlock from '../author-block/watson-theme-author-block.vue';
import decodeHtmlEntities from '../../../../js/utils/decode-html-entities';

defineProps({
    authors: {
        type: Object,
        required: true,
    },
    disabledImage: {
        type: Boolean,
        default: false,
    },
    headingTag: {
        type: String,
        default: 'h1',
    },
});

const page = usePage();
const pageSettings = page.props.pageSettings ?? {};
</script>

<style lang="scss">
@use 'watson-theme-article-header';
</style>
