<template>
    <section class="error-page">
        <WatsonOrganismErrorContent
            :brand-settings="brandSettings"
        />
    </section>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

import WatsonOrganismErrorContent from '@organisms/error-content/watson-organism-error-content.vue';

const page = usePage();
const brandSettings = page.props.brand;
</script>
