<template>
    <WatsonOrganismContentLayout>
        <template #content>
            <div class="sitemap">
                <h1 class="sitemap__title u-heading-1">
                    {{ translations.title }}
                </h1>

                <div class="sitemap__container">
                    <div
                        v-for="category in structuredData"
                        class="sitemap__group"
                    >
                        <h2 class="sitemap__group-title u-heading-2">
                            {{ stripHtml(category.name) }}
                        </h2>

                        <ul
                            v-if="category.name !== 'Pages'"
                            class="sitemap__category-list"
                        >
                            <li
                                v-for="item in category.items"
                                :key="item.id"
                                class="sitemap__category-list-item"
                            >
                                <h3
                                    class="sitemap__category-title u-heading-3"
                                >
                                    <Link
                                        :href="item.url || item.link"
                                        class="sitemap__link"
                                    >
                                        {{ stripHtml(item.name) }}
                                    </Link>
                                </h3>

                                <ul
                                    v-if="item.postTypes.length"
                                    class="sitemap__category-list"
                                >
                                    <li
                                        v-for="postType in item.postTypes"
                                        :key="postType.id"
                                        class="sitemap__category-list-item"
                                    >
                                        <Link
                                            :href="postType.url || postType.link"
                                            class="sitemap__link"
                                        >
                                            {{ stripHtml(postType.name) }}
                                        </Link>
                                    </li>
                                </ul>

                                <ul
                                    v-if="item.childrenTerms.length"
                                    class="sitemap__category-list"
                                >
                                    <template
                                        v-for="child in item.childrenTerms"
                                        :key="child.id"
                                    >
                                        <li
                                            v-for="postType in child.postTypes"
                                            :key="postType.id"
                                            class="sitemap__category-list-item"
                                        >
                                            <Link
                                                :href="postType.url || postType.link"
                                                class="sitemap__link"
                                            >
                                                {{ stripHtml(postType.name) }}
                                            </Link>
                                        </li>
                                    </template>
                                </ul>
                            </li>
                        </ul>

                        <ul
                            v-if="category.items.length && (category.name === 'Pages')"
                            class="sitemap__category-list sitemap__category-list--pages"
                        >
                            <li
                                v-for="page in category.items.filter(item => item.children.length === 0)"
                                :key="page.ID"
                                class="sitemap__category-list-item"
                            >
                                <Link
                                    :href="page.link || page.url"
                                    class="sitemap__link"
                                >
                                    {{ stripHtml(page.name) }}
                                </Link>
                            </li>

                            <li
                                v-for="page in category.items.filter(item => item.children.length > 0)"
                                :key="page.id"
                                class="sitemap__category-list-item"
                            >
                                <h3 class="sitemap__category-title u-heading-3">
                                    <Link
                                        :href="page.link || page.url"
                                        class="sitemap__link"
                                    >
                                        {{ stripHtml(page.name) }}
                                    </Link>
                                </h3>
                                <ul class="sitemap__category-list">
                                    <li
                                        v-for="child in page.children"
                                        :key="child.id"
                                        class="sitemap__category-list-item"
                                    >
                                        <Link
                                            :href="child.url || child.link"
                                            class="sitemap__link"
                                        >
                                            {{ stripHtml(child.name) }}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </WatsonOrganismContentLayout>
</template>

<script setup>
import WatsonOrganismContentLayout from '@organisms/content-layout/watson-organism-content-layout.vue';
import stripHtml from '../../../../js/utils/strip-html';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const translations = page.props.settings.translations?.sitemap || {};

const props = defineProps({
    sitemapData: {
        type: Object,
        required: true,
    },
});

const structureSitemap = data => {
    const result = {
        priceGuides: {
            name: 'Guides des prix',
            items: [],
        },
        magazines: {
            name: 'Magazine',
            items: [],
        },
        pages: {
            name: 'Pages',
            items: [],
        },
    };

    if (data.taxonomyPages[0]) {
        result.priceGuides.items = data.taxonomyPages[0].categories;
    }

    if (data.taxonomyPages[1]) {
        result.magazines.items = data.taxonomyPages[1].categories;
    }

    if (data.pages) {
        result.pages.items = data.pages;
    }

    return result;
};

const structuredData = structureSitemap(props.sitemapData);
</script>

<style lang="scss">
@use 'sitemap-index';
</style>
