<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 499 92"
        preserveAspectRatio="none"
    >
        <defs>
            <linearGradient
                id="linear-gradient"
                x1="249"
                y1="-155.5"
                x2="498"
                y2="-155.5"
                gradientTransform="translate(0 -110) scale(1 -1)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset=".6" stop-color="#b7dbe9" />
                <stop offset=".7" stop-color="#b9dce9" />
                <stop offset=".7" stop-color="#bddeeb" stop-opacity=".9" />
                <stop offset=".8" stop-color="#c5e2ed" stop-opacity=".8" />
                <stop offset=".8" stop-color="#d0e7f1" stop-opacity=".7" />
                <stop offset=".9" stop-color="#deeef5" stop-opacity=".5" />
                <stop offset=".9" stop-color="#eef7fa" stop-opacity=".2" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="linear-gradient1"
                x1="249"
                y1="-155.5"
                x2="0"
                y2="-155.5"
                gradientTransform="translate(0 -110) scale(1 -1)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset=".6" stop-color="#b7dbe9" />
                <stop offset=".7" stop-color="#b9dce9" />
                <stop offset=".7" stop-color="#bddeeb" stop-opacity=".9" />
                <stop offset=".8" stop-color="#c5e2ed" stop-opacity=".8" />
                <stop offset=".8" stop-color="#d0e7f1" stop-opacity=".7" />
                <stop offset=".9" stop-color="#deeef5" stop-opacity=".5" />
                <stop offset=".9" stop-color="#eef7fa" stop-opacity=".2" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
            </linearGradient>
        </defs>
        <path class="st1" d="M159,84.3l2.3,1.3L297.7,10.2c-1-.3-1.9-.6-2.9-.9l-135.8,75h0Z" />
        <path class="st1" d="M159,58.8l2.3,1.3L264.1,3.3c-1.3,0-2.7-.2-4-.3l-101.1,55.9h0Z" />
        <path class="st1" d="M159,46l2.3,1.3L241.7,2.9c-1.9,0-3.7.3-5.6.5l-77.2,42.6h.1Z" />
        <path class="st1" d="M159,78l2.3,1.3L290.2,8c-1-.3-2.1-.6-3.1-.8l-128.1,70.8Z" />
        <path class="st1" d="M159,71.6l2.3,1.3L282.2,6c-1.1-.2-2.2-.5-3.4-.7l-119.8,66.2h0Z" />
        <path class="st1" d="M159,65.2l2.3,1.3L273.5,4.4c-1.2-.2-2.4-.4-3.6-.5l-110.9,61.3Z" />
        <path class="st1" d="M159,39.6l2.3,1.3L226.5,4.9c-2.6.5-5.1,1.1-7.6,1.7l-59.9,33.1h0Z" />
        <path class="st1" d="M170.6,90.7l2.3,1.3L311.4,15.5c-.8-.4-1.7-.7-2.6-1.1l-138.2,76.4h0Z" />
        <path class="st1" d="M205.2,90.7l2.3,1.3,121.6-67.2c-.7-.4-1.4-.9-2.2-1.3l-121.7,67.2h0Z" />
        <path class="st1" d="M182.1,90.7l2.3,1.3L317.7,18.4c-.8-.4-1.6-.8-2.5-1.2l-133.1,73.5h0Z" />
        <path class="st1" d="M249.2,2.6l-90.2,49.8,2.3,1.3L253.7,2.6h-4.5Z" />
        <path class="st1" d="M159,90.7l2.3,1.3L304.8,12.7c-.9-.3-1.8-.7-2.8-1l-143,79h0Z" />
        <path class="st1" d="M193.7,90.7l2.3,1.3,127.6-70.5c-.7-.4-1.5-.8-2.3-1.3l-127.6,70.5h0Z" />
        <path class="st2" d="M159,33.2l2.3,1.3,42.7-23.6c-5.3,1.8-10.4,3.8-15,5.8l-30,16.6h0Z" />
        <path class="st0" d="M320.9,90.7l2.3,1.3,12.5-6.9-2.3-1.3-12.5,6.9Z" />
        <path class="st1" d="M333.4,39.1l-93.5,51.6,2.3,1.3,93.5-51.6-2.3-1.3h0Z" />
        <path class="st1" d="M332.4,26.9l-115.6,63.8,2.3,1.3,115.3-63.7c-.6-.4-1.2-.9-2-1.4Z" />
        <path class="st1" d="M333.4,32.7l-105,58,2.3,1.3,105-58-2.3-1.3h0Z" />
        <path class="st1" d="M309.3,90.7l2.3,1.3,24.1-13.3-2.3-1.3-24.1,13.3Z" />
        <path class="st1" d="M263,90.7l2.3,1.3,70.4-38.9-2.3-1.3-70.4,38.9Z" />
        <path class="st2" d="M335.7,27.6l-2.3-1.3-1.1.6c.8.5,1.5,1,2,1.4l1.3-.7h.1Z" />
        <path class="st1" d="M251.5,90.7l2.3,1.3,81.9-45.3-2.3-1.3-81.9,45.3h0Z" />
        <path class="st1" d="M286.2,90.7l2.3,1.3,47.2-26.1-2.3-1.3-47.2,26.1Z" />
        <path class="st1" d="M274.6,90.7l2.3,1.3,58.8-32.5-2.3-1.3-58.8,32.5Z" />
        <path class="st1" d="M297.7,90.7l2.3,1.3,35.7-19.7-2.3-1.3-35.7,19.7Z" />
        <path class="st8" d="M498,88c-9.2,0-75.4,1.8-150.2-50.8,0,0-45.4-33.3-96.8-34.2h-2" />
        <path class="st5" d="M0,88c9.2,0,75.4,1.8,150.2-50.8,0,0,45.4-33.3,96.8-34.2h2" />
        <path class="st4" d="M336,32v1" />
        <path class="st3" d="M336,35v53" />
        <path class="st4" d="M336,88.9v1.1M159,34v1.1" />
        <path class="st7" d="M159,37v51" />
        <path class="st4" d="M159,89v1" />
        <path class="st6" d="M157,33S203,2,251.6,3c46.5,1,86.4,27.9,86.4,27.9" />
    </svg>
</template>

<style lang="scss" scoped>
.st0 {
    opacity: .5;
}

.st0, .st1 {
    isolation: isolate;
}

.st0, .st1, .st2 {
    fill: #aed0de;
    fill-opacity: .1;
}

.st3 {
    stroke-dasharray: 3.4 3.4;
}

.st3, .st4, .st5, .st6, .st7, .st8 {
    fill: none;
    stroke-miterlimit: 10;
}

.st3, .st4, .st7 {
    stroke-width: .9px;
}

.st3, .st6, .st7 {
    stroke: #0098e4;
}

.st4 {
    stroke: #509dd5;
}

.st5 {
    stroke: url(#linear-gradient1);
}

.st5, .st6, .st8 {
    stroke-width: 5.1px;
}

.st7 {
    stroke-dasharray: 3.5 3.5;
}

.st8 {
    stroke: url(#linear-gradient);
}
</style>
