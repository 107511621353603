<template>
    <watson-organism-content-layout
        content-class="magazine-category"
    >
        <template #content>
            <header class="magazine-category__header">
                <h1 class="magazine-category__title">
                    {{ decodeHtmlEntities(content.name) }}
                </h1>

                <p class="magazine-category__description">
                    {{ content.description }}
                </p>

                <WatsonThemeUsps
                    v-if="postTypes.usps"
                    :content="postTypes.usps"
                    v-bind="postTypes.usps"
                />
            </header>

            <WatsonThemeListMagazineArticles
                :content="{}"
                :items="items"
                :typography="defaultTypography"
            />
        </template>
    </watson-organism-content-layout>
</template>

<script setup>
import { ref } from 'vue';

import decodeHtmlEntities from '@utils/decode-html-entities';

import WatsonOrganismContentLayout from '@organisms/content-layout/watson-organism-content-layout.vue';
import WatsonThemeListMagazineArticles from '../../components/list-magazine-articles/watson-theme-list-magazine-articles.vue';
import WatsonThemeUsps from '../../components/usps/watson-theme-usps.vue';

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
    postTypes: {
        type: Object,
        required: true,
    },
});

const items = ref([]);

props.content.postTypes.forEach(post => {
    items.value.push({
        url: post.url,
        title: post.name,
        description: post.description,
        image: post.image || {},
        date: post.date,
    });
});

const defaultTypography = {
    heading: {
        visual: 'heading-3-bold',
        tag: 'h2',
    },

    cardTitle: {
        visual: 'paragraph-bold',
        tag: 'h3',
    },
};
</script>

<style lang="scss">
@use 'index';
</style>
