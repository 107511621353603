<template>
    <WatsonOrganismContentLayout>
        <template #content>
            <WatsonOrganismBreadcrumbs
                :items="[
                    {
                        label: 'Accueil',
                    },
                    ...parents.map(parent => ({
                        label: parent.name,
                        url: parent.url,
                    })),
                    {
                        label: pageSettings.title || pageSettings.editorTitle,
                        url: page.props.url,
                    }
                ]"
            />

            <WatsonThemeArticleHeader
                :authors="authors"
            />

            <Component
                :is="componentName(component)"
                v-for="(component, index) in content"
                :key="index"
                :content="component"
                v-bind="component.attrs.bindData"
            />

            <WatsonOrganismIconLinkBar
                v-if="iconLinkBar && !iconLinkBar.disable"
            />

            <WatsonThemeAuthorBlockPageEnd
                v-if="authors.writer"
                :content="authors"
            />

            <WatsonThemeLinkBlock v-if="!linkBlockState && linkBlockData.length > 0" />
        </template>

        <template #sidebar>
            <WatsonThemeUsps
                v-if="postTypes.usps"
                :content="postTypes.usps"
                v-bind="postTypes.usps"
            />

            <div>
                <WatsonOrganismJumpLinksVertical
                    v-if="!jumpLinksVertical.hide"
                    :hide="jumpLinksVertical.hide"
                    :title="jumpLinksVertical.title"
                    :collapsable="true"
                />

                <WatsonThemeCtaBlock
                    v-if="postTypes.sidebarCtaBlock"
                    v-bind="postTypes.sidebarCtaBlock"
                    :heading="sidebarCtaBlock.heading || postTypes.sidebarCtaBlock.heading"
                    :subheading="sidebarCtaBlock.subheading || postTypes.sidebarCtaBlock.subheading"
                    :button="{
                        ...postTypes.sidebarCtaBlock.button,
                        text: sidebarCtaBlock.buttonText || postTypes.sidebarCtaBlock.button.text,
                    }"
                    :tiny-text="sidebarCtaBlock.tinyText || postTypes.sidebarCtaBlock.tinyText"
                />

                <WatsonThemeListLogos
                    v-if="postTypes.listLogos"
                    v-bind="postTypes.listLogos"
                />
            </div>
        </template>
    </WatsonOrganismContentLayout>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

import componentName from '@utils/component-name';
import { useTableOfContents } from '../../../../js/stores/table-of-contents';

import WatsonOrganismContentLayout from '@organisms/content-layout/watson-organism-content-layout.vue';
import WatsonThemeArticleHeader from '../../components/article-header/watson-theme-article-header.vue';
import WatsonThemeAuthorBlockPageEnd from '../../components/author-block-page-end/watson-theme-author-block-page-end.vue';
import WatsonThemeCtaBlock from '../../components/cta-block/watson-theme-cta-block.vue';
import WatsonThemeListLogos from '../../components/list-logos/watson-theme-list-logos.vue';
import WatsonThemeLinkBlock from '../../components/link-block/watson-theme-link-block.vue';
import WatsonThemeUsps from '../../components/usps/watson-theme-usps.vue';
import WatsonOrganismJumpLinksVertical from '@organisms/jump-links-vertical/watson-organism-jump-links-vertical.vue';
import WatsonOrganismBreadcrumbs from '@organisms/breadcrumbs/watson-organism-breadcrumbs.vue';
import WatsonOrganismIconLinkBar from '@organisms/icon-link-bar/watson-organism-icon-link-bar.vue';

const props = defineProps({
    content: {
        type: Array,
        required: true,
    },
    authors: {
        type: Object,
        required: true,
    },
    pageSettings: {
        type: Object,
        required: true,
    },
    parents: {
        type: Array,
        required: true,
    },
    postTypes: {
        type: Object,
        required: true,
    },
    sidebarCtaBlock: {
        type: Object,
        required: true,
    },
    jumpLinksVertical: {
        type: Object,
        required: false,
        default: null,
    },
    linkBlock: {
        type: Object,
        required: false,
        default: null,
    },
    linkBlockData: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const page = usePage();
const iconLinkBar = page.props.settings.globalComponents.iconLinkBar;

const tableOfContents = useTableOfContents(`table-of-contents-${page.props.url}`);
tableOfContents.enabled = props.jumpLinksVertical ? !props.jumpLinksVertical.hide : false;
tableOfContents.initializeHeadings(props.content);

const linkBlockState = props.linkBlock ? props.linkBlock.disabled : false;
</script>
