<template>
    <watson-organism-content-layout
        content-class="magazine-overview"
    >
        <template #content>
            <h1 class="magazine-overview__title">
                {{ decodeHtmlEntities(pageSettings.title || pageSettings.editorTitle) }}
            </h1>

            <WatsonThemeListMagazineArticles
                v-for="(item, index) in items"
                :key="index"
                :content="{}"
                :heading="item.heading"
                :items="item.items"
                :typography="defaultTypography"
                :link-label="item.linkLabel"
                :link-url="item.linkUrl"
                :card-style="{
                    titleColor: '#000616',
                    titleHoverColor: '#000616',
                }"
            />
        </template>
    </watson-organism-content-layout>
</template>

<script setup>
import { ref } from 'vue';

import decodeHtmlEntities from '@utils/decode-html-entities';

import WatsonOrganismContentLayout from '@organisms/content-layout/watson-organism-content-layout.vue';
import WatsonThemeListMagazineArticles from '../../components/list-magazine-articles/watson-theme-list-magazine-articles.vue';

const props = defineProps({
    pageSettings: {
        type: Object,
        required: true,
    },
    magazineOverview: {
        type: Object,
        required: true,
    },
    categories: {
        type: Object,
        required: true,
    },
});

const items = ref([]);

props.categories.childrenTerms.forEach(term => {
    items.value.push({
        heading: term.name,
        items: term.postTypes.map(post => ({
            url: post.url,
            title: post.name,
            description: post.description,
            alternativeDescription: post.alternativeDescription,
            image: post.image || {},
            date: post.date,
        })),
        linkLabel: props.magazineOverview.readMoreText,
        linkUrl: term.totalChildren > 6 ? term.url : null,
    });
});

const defaultTypography = {
    heading: {
        visual: 'heading-3-bold',
        tag: 'h2',
    },

    cardTitle: {
        visual: 'paragraph-bold',
        tag: 'h3',
    },
};
</script>

<style lang="scss">
@use 'index';
</style>
