<template>
    <div class="header-product-container">
        <header class="header-product">
            <div class="header-product__inner">
                <div class="header-product__top">
                    <h1
                        v-if="priceGuide.title"
                        class="header-product__heading"
                    >
                        {{ decodeHtmlEntities(priceGuide.title) }}
                    </h1>
                </div>
                <div class="header-product__content">
                    <div class="header-product__left">
                        <WatsonThemeProductGraph
                            :price-guide="priceGuide"
                        />

                        <form
                            v-if="ctaBlock"
                            class="header-product__form"
                            @submit.prevent="submitForm"
                        >
                            <div class="header-product__cta-title u-heading-4">
                                {{ ctaBlockOverwrite.heading || ctaBlock.heading }}
                            </div>

                            <div class="header-product__cta-text">
                                {{ ctaBlockOverwrite.subheading || ctaBlock.subheading }}
                            </div>

                            <DsAtomInput
                                v-if="ctaBlock.input.show"
                                id="input"
                                name="input"
                                type="text"
                                class="header-product__input"
                                :label="ctaBlock.input.label"
                                :placeholder="ctaBlock.input.placeholder"
                                :mask="ctaBlock.input.mask"
                                :validation="ctaBlock.input.validation"
                                :value="inputValue"
                                :is-valid="valid"
                                size="big"
                                :error="ctaBlock.input.errorMessage"
                                :icon="ctaBlock.input.icon"
                                :prefix="ctaBlock.input.prefix"
                                :suffix="ctaBlock.input.suffix"
                                @update-value="updateValue"
                                @update-validation="updateValidation"
                            />

                            <div class="header-product__button-container">
                                <button class="header-product__button">
                                    <span
                                        class="header-product__button-label"
                                        :class="typographyUtilityClasses(ctaBlock.typography.buttonText)"
                                    >
                                        {{ ctaBlockOverwrite.buttonLabel || ctaBlock.button.text }}

                                        <FontAwesomeIcon
                                            v-if="buttonIcon"
                                            :icon="buttonIcon"
                                        />
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div
                        v-if="ctaBlock && ctaBlock.usps"
                        class="header-product__divider"
                    ></div>
                    <div
                        v-if="ctaBlock && ctaBlock.usps"
                        class="header-product__usps"
                    >
                        <WatsonOrganismContentColumnCheckmarks
                            :content="{}"
                            :items="ctaBlock.usps.items"
                            column-count="1"
                            :turn-on-background="false"
                            :background="null"
                            :icon-color="ctaBlock.usps.iconColor"
                            :text-color="ctaBlock.usps.textColor"
                            :typography="ctaBlock.usps.typography"
                            media-position="next-to-text"
                        />
                    </div>
                </div>
            </div>
        </header>
        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="priceGuide"
            v-html="`<script type=&quot;application/ld+json&quot;>${JSON.stringify(structuredData)}</script>`"
        ></div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { typographyUtilityClasses } from '@utils/typography';

import { validateValue } from 'design-system-component-library';
import { DsAtomInput } from 'design-system-component-library/marketplaces';

import WatsonOrganismContentColumnCheckmarks from '@organisms/content-column-checkmarks/watson-organism-content-column-checkmarks.vue';
import WatsonThemeProductGraph from '../product-graph/watson-theme-product-graph.vue';
import { handleClickAction } from '@utils/click-action';
import decodeHtmlEntities from '../../../../js/utils/decode-html-entities';

const props = defineProps({
    priceGuide: {
        type: Object,
        required: true,
    },
    ctaBlock: {
        type: Object,
        required: false,
        default: null,
    },
    ctaBlockOverwrite: {
        type: Object,
        required: false,
        default: () => ({}),
    },
});

const inputValue = ref('');
const valid = ref(true);

const submitForm = () => {
    if (props.ctaBlock.input.show) {
        if (!validateValue(inputValue.value, props.ctaBlock.input.validation)) {
            valid.value = false;
            return;
        }
    }

    handleClickAction(props.ctaBlock.clickAction, inputValue.value);
};

const updateValue = value => {
    inputValue.value = value;
};

const updateValidation = value => {
    valid.value = value;
};

const buttonIcon = {
    'arrow-right': faArrowRight,
}[props.ctaBlock?.button?.icon];

const generateStructuredData = priceGuide => {
    return {
        '@context': 'https://schema.org',
        '@type': 'MonetaryAmount',
        'currency': 'EUR',
        'value': 'Number',
        'minValue': priceGuide.lowPrice,
        'maxValue': priceGuide.highPrice,
    };
};

const structuredData = generateStructuredData(props.priceGuide);
</script>

<style lang="scss">
@use 'watson-theme-header-product';
</style>
