<template>
    <section class="partners-media-container">
        <div class="partners-media content-container">
            <div class="partners-media__container">
                <h1 class="partners-media__title">
                    {{ translations.pageTitle }}
                </h1>

                <h2 class="partners-media__subtitle  u-heading-4">
                    {{ translations.mediaTitle }}
                </h2>

                <div class="partners-media__partners">
                    <div
                        v-for="item in media"
                        class="partners-media__partner"
                    >
                        <WatsonThemePartnerHighlight :content="item" />
                    </div>
                </div>

                <h2 class="partners-media__subtitle u-heading-4">
                    {{ translations.partnerTitle }}
                </h2>

                <div class="partners-media__partners">
                    <div
                        v-for="item in partners"
                        class="partners-media__partner"
                    >
                        <WatsonThemePartnerHighlight :content="item" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import WatsonThemePartnerHighlight from '../../components/partner-highlight/watson-theme-partner-highlight.vue';
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    content: {
        type: Array,
        required: true,
    },
    partnersMedia: {
        type: Object,
        required: true,
    },
});

const page = usePage();
const translations = page.props.settings.translations.partnersMedia;

const media = props.partnersMedia.items.filter(partner => partner.type === 'media');
const partners = props.partnersMedia.items.filter(partner => partner.type === 'partner');
</script>

<style lang="scss">
@use 'partners-index';
</style>

<style lang="scss" scoped>
@layer theme {
    .partners-media {
        --partner-background: v-bind('props.partnersMedia.style.background');
        --partner-border-color: v-bind('props.partnersMedia.style.border');
        --partner-title-color: v-bind('props.partnersMedia.style.title');
        --partner-date-color: v-bind('props.partnersMedia.style.date');
        --partner-link-color: v-bind('props.partnersMedia.style.link');
        --partner-link-hover-color: v-bind('props.partnersMedia.style.linkHover');
    }
}</style>
