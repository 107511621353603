<template>
    <section class="link-block-container">
        <div class="link-block">
            <h3>{{ translation.linkBlock?.title || '' }}</h3>

            <div class="link-block__columns">
                <ul
                    v-for="(column, index) in columns"
                    :key="index"
                    class="link-block__list"
                >
                    <li
                        v-for="item in column"
                        :key="item.key"
                        class="link-block__item"
                    >
                        <a
                            :href="item.link || item.url"
                            class="link-block__item-link"
                        >
                            {{ item.title }}
                        </a>

                        <ul
                            v-if="item.children?.length > 0"
                            class="link-block__list link-block__list--secondary"
                        >
                            <li
                                v-for="subitem in item.children"
                                :key="subitem.key"
                                class="link-block__item u-tiny-text"
                            >
                                <a
                                    :href="subitem.link || subitem.url"
                                    class="link-block__item-link"
                                >
                                    {{ subitem.title }}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { ref } from 'vue';

const page = usePage();
const translation = page.props.settings.translations;
const columns = ref([]);

const distributeIntoColumns = data => {
    const itemsWithChildren = data.map(parent => {
        const childCount = parent.children?.length || 0;

        return { ...parent, totalItems: 1 + childCount };
    });

    itemsWithChildren.sort((a, b) => b.totalItems - a.totalItems);

    const columns = [[], [], []];
    const columnCounts = [0, 0, 0];

    itemsWithChildren.forEach(parent => {
        const minIndex = columnCounts.indexOf(Math.min(...columnCounts));

        columns[minIndex].push(parent);
        columnCounts[minIndex] += parent.totalItems;
    });

    return columns;
};

const data = page.props.linkBlockData || [];

columns.value = distributeIntoColumns(data);
</script>

<style lang="scss">
@use 'watson-theme-link-block';
</style>
