<template>
    <div class="author-page">
        <WatsonThemeHeaderProfile :author="author" />

        <WatsonOrganismContentLayout>
            <template #content>
                <h2 class="author-page__title wordpress-heading u-heading-2 wordpress-heading--h2">
                    {{ translations.about }}
                </h2>

                <Component
                    :is="componentName(component)"
                    v-for="(component, index) in content"
                    :key="index"
                    :content="component"
                    v-bind="component.attrs.bindData"
                />
            </template>
        </WatsonOrganismContentLayout>

        <WatsonThemeListPopularServices
            v-if="recommendedArticles.items.length > 0"
            :title="recommendedArticles.title"
            :items="recommendedArticles.items"
        />

        <WatsonThemeListMagazineArticles
            :content="{}"
            :items="items"
            :heading="recentArticlesTitle"
            :typography="defaultTypography"
        />

        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="!hidestructureddata"
            v-html="`<script type=&quot;application/ld+json\&quot;>${JSON.stringify(structuredData)}</script>`"
        ></div>
    </div>
</template>

<script setup>
import WatsonThemeListMagazineArticles from '../../components/list-magazine-articles/watson-theme-list-magazine-articles.vue';
import WatsonOrganismContentLayout from '@organisms/content-layout/watson-organism-content-layout.vue';
import WatsonThemeHeaderProfile from '../../components/header-profile/watson-theme-header-profile.vue';
import { usePage } from '@inertiajs/vue3';
import WatsonThemeListPopularServices
    from '../../components/list-popular-services/watson-theme-list-popular-services.vue';
import componentName from '@utils/component-name';
import { ref } from 'vue';

const page = usePage();
const props = defineProps({
    content: {
        type: Array,
        required: true,
    },
    author: {
        type: Object,
        required: true,
    },
    recommendedArticles: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    recentArticles: {
        type: Object,
        required: true,
    },
    hidestructureddata: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const translations = page.props.settings?.translations?.authorPage || { about: 'About' };

const recentArticlesTitle = `${translations.recentArticles || 'Recent Articles from'} ${props.author.name}`;

const items = ref([]);

props.recentArticles.pages.forEach(post => {
    items.value.push({
        url: post.link,
        title: post.title,
        image: post.image || {},
        date: post.date,
    });
});

const stripHtml = html => {
    return html?.replace(/<[^>]*>?/gm, '').replace(/\n/g, ' ').trim();
};

const generateStructuredData = author => {
    const person = {
        '@context': 'https://schema.org',
        '@type': 'Person',
        'name': author.name,
        'jobTitle': author.jobTitle,
        'description': stripHtml(author.description),
        'disambiguatingDescription': author.shortDescription,
        'image': author.picture?.url,
        'url': author.url,
    };

    if (author.worksFor?.length) {
        person.worksFor = author.worksFor.map(work => ({
            '@type': 'Organization',
            'name': work.name,
            'url': work.url,
        }));
    }

    if (author.socials) {
        person.sameAs = Object.entries(author.socials)
            .filter(([, value]) => value && value.url)
            .map(([, value]) => value.url);
        if (author.socials.email) person.email = author.socials.email;
    }

    if (author.areasOfExpertise?.length) {
        person.knowsAbout = author.areasOfExpertise.map(area => ({
            '@type': 'Thing',
            'name': area.name,
            'sameAs': [area.url].filter(Boolean),
            '@id': area.id,
        }));
    }

    if (author.alumniOf?.length) {
        person.alumniOf = author.alumniOf.map(alumni => ({
            '@type': 'EducationalOrganization',
            'name': alumni.name,
            'url': alumni.url,
            'sameAs': alumni.sameAs,
        }));
    }

    if (author.credentials?.length) {
        person.hasCredential = author.credentials.map(credential => {
            const credentialObject = {
                '@type': 'EducationalOccupationalCredential',
                'educationalLevel': credential.level,
                'credentialCategory': credential.category,
            };

            if (credential.recognizedBy?.length) {
                credentialObject.recognizedBy = credential.recognizedBy.map(recognition => ({
                    '@type': recognition.type,
                    'name': recognition.name,
                    'sameAs': recognition.url,
                }));
            }

            return credentialObject;
        });
    }

    return person;
};

const defaultTypography = {
    heading: {
        visual: 'heading-3-bold',
        tag: 'h2',
    },

    cardTitle: {
        visual: 'paragraph-bold',
        tag: 'h3',
    },
};

const structuredData = generateStructuredData(props.author);
</script>
