<template>
    <div class="authors-page">
        <WatsonOrganismContentLayout>
            <template #content>
                <section class="authors-page__header">
                    <Component
                        :is="componentName(component)"
                        v-for="(component, index) in content"
                        :key="index"
                        :content="component"
                        v-bind="component.attrs.bindData"
                    />
                </section>

                <section class="authors-page__items">
                    <WatsonThemeListProfileItem
                        v-for="(author, index) in authors"
                        :key="index"
                        :url="author.url ? author.url : ''"
                        :picture="author.picture"
                        :title="author.name + ` | ` + author.job_title"
                        :description="author.short_description"
                        :typography="defaultTypography"
                    />
                </section>
            </template>
        </WatsonOrganismContentLayout>
    </div>
</template>

<script setup>
import WatsonOrganismContentLayout from '@organisms/content-layout/watson-organism-content-layout.vue';
import WatsonThemeListProfileItem from '../../components/list-profile-item/watson-theme-list-profile-item.vue';

import componentName from '@utils/component-name';

defineProps({
    content: {
        type: Array,
        required: true,
    },
    authors: {
        type: Object,
        required: true,
    },
});

const defaultTypography = {
    profileTitle: {
        visual: 'paragraph-bold',
        tag: 'p',
    },
    profileDescription: {
        visual: 'paragraph',
        tag: 'p',
    },
};
</script>

<style lang="scss">
@use 'authors-index';
</style>
