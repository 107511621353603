<template>
    <div class="author-block-container">
        <div class="author-block">
            <div class="author-block__authors">
                <div
                    v-for="(collaborator, i) in [content.writer, ...content.collaborators]"
                    :key="i"
                    class="author-block__author"
                >
                    <WatsonAtomImage
                        v-if="collaborator.image?.url"
                        :content="collaborator.image"
                        class="author-block__image"
                    />

                    <div class="author-block__text-container">
                        <p class="author-block__label u-tiny-text">
                            {{ translations[collaborator.type] || '' }}
                        </p>

                        <Link
                            v-if="collaborator.link"
                            class="author-block__link"
                            :href="collaborator.link"
                        >
                            <p
                                v-if="collaborator.name"
                                class="author-block__name u-tiny-text u-bold"
                            >
                                {{ collaborator.name }}
                            </p>
                        </Link>
                    </div>
                </div>
            </div>

            <p
                v-if="lastUpdated"
                class="author-block__last-updated u-tiny-text"
            >
                {{ lastUpdated }}
            </p>
        </div>
    </div>
</template>

<script setup>
import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';
import { usePage } from '@inertiajs/vue3';

defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const page = usePage();
const translations = page.props.settings.translations?.authorBlock || {};
const lastUpdated = `${translations.lastUpdated} ${page.props.lastUpdated}`;
</script>

<style lang="scss">
@use 'watson-theme-author-block';
</style>
