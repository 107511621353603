<template>
    <WatsonThemeHeaderCategory
        :title="content.name"
        :text="sanitizedDescription"
        :image="content.image"
    />

    <div class="price-guide-category-wrapper">
        <WatsonOrganismJumpLinksHorizontal
            v-if="Object.keys(items).length > 1"
            class="price-guide-category__jump-links"
            :items="items"
        />
        <div class="price-guide-category">
            <WatsonThemeListServiceCategories
                v-for="(item, index) in items"
                :id="item.id"
                :key="index"
                :title="item.name"
                :items="item.items"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import stripHtml from '@utils/strip-html';

import WatsonThemeHeaderCategory from '../../components/header-category/watson-theme-header-category.vue';
import WatsonThemeListServiceCategories from '../../components/list-service-categories/watson-theme-list-service-categories.vue';
import WatsonOrganismJumpLinksHorizontal from '@organisms/jump-links-horizontal/watson-organism-jump-links-horizontal.vue';
import slug from 'slug';

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const sanitizedDescription = computed(() =>
    props.content?.description?.replace(/\n/g, '<br>') ?? '',
);

const items = ref({});

const sortPostTypes = postTypes => {
    const sortedPostTypes = {};

    postTypes.forEach(item => {
        if (item.parent) {
            if (!sortedPostTypes[item.parent]) {
                sortedPostTypes[item.parent] = { links: {} };
            }
            sortedPostTypes[item.parent].links[item.id] = {
                label: item.name,
                url: item.url,
            };
        } else {
            sortedPostTypes[item.id] = {
                title: item.name,
                description: stripHtml(item.description || ''),
                image: item.priceGuideIcon,
                url: item.url,
                links: {},
            };
        }
    });

    return sortedPostTypes;
};

items.value = props.content.childrenTerms.map(item => ({
    name: item.name,
    id: slug(item.name),
    items: sortPostTypes(item.postTypes),
}));
</script>

<style lang="scss">
@use 'price-guide-category-index';
</style>
