<template>
    <section class="author-block-end-container">
        <div class="author-block-end">
            <div class="author-block-end__author">
                <div class="author-block-end__author-top">
                    <WatsonAtomImage
                        v-if="content.writer.image"
                        :content="content.writer.image"
                        class="author-block-end__author-image"
                    />

                    <div class="author-block-end__author-properties">
                        <Link
                            class="author-block-end__author-name u-paragraph u-bold"
                            :href="content.writer.link"
                        >
                            {{ content.writer.name }}
                        </Link>

                        <p class="author-block-end__author-job u-tiny-text">
                            {{ content.writer.title }}
                        </p>
                    </div>
                </div>

                <p class="author-block-end__author-description u-paragraph">
                    {{ stripHtml(content.writer.description) }}
                </p>
            </div>

            <div
                v-if="content.collaborators.length > 0"
                class="author-block-end__separator"
            ></div>

            <div
                v-if="content.collaborators.length > 0"
                class="author-block-end__contributors"
            >
                <div
                    v-for="collaborator in content.collaborators"
                    class="author-block-end__contributor"
                >
                    <WatsonAtomImage
                        v-if="collaborator.image"
                        :content="collaborator.image"
                        class="author-block-end__contributor-image"
                    />

                    <div class="author-block-end__contributor-properties">
                        <p class="author-block-end__contributor-action u-tiny-text">
                            {{ translations[collaborator.type] || '' }}
                        </p>

                        <Link
                            class="author-block-end__contributor-name u-tiny-text u-bold"
                            :href="collaborator.link"
                        >
                            {{ collaborator.name }}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import stripHtml from '../../../../js/utils/strip-html';

import WatsonAtomImage from '../../../../components/atoms/image/watson-atom-image.vue';

defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const page = usePage();
const translations = page.props.settings.translations?.authorBlock || {};
</script>

<style lang="scss">
@use 'watson-theme-author-block-page-end';
</style>
