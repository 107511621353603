<template>
    <div class="search-container">
        <section class="search">
            <p
                v-if="results.priceGuides.length || results.magazines.length || results.pages.length"
                class="search__query u-paragraph u-bold"
            >
                {{ translations.resultFor || 'Results for' }} "{{ query }}"
            </p>

            <div
                v-if="results.priceGuides.length"
                class="search__group"
            >
                <h2 class="search__group-title">
                    {{ translations.priceGuides || 'Price Guides' }}
                </h2>

                <ul class="search__group-list">
                    <li
                        v-for="result in results.priceGuides"
                        :key="result.id"
                        class="search__group-list-item"
                    >
                        <WatsonThemeLinkCard
                            :content="result"
                            :url-text="translations.priceGuidesButtonText || 'Estimate your project'"
                        />
                    </li>
                </ul>

                <button
                    v-if="canAddResults('priceGuides')"
                    class="search__group-button u-heading-4 u-bold"
                    @click="addResults('priceGuides')"
                >
                    {{ translations.loadMore || 'See more results' }}
                </button>
            </div>

            <div
                v-if="results.magazines.length"
                class="search__group"
            >
                <h2 class="search__group-title">
                    {{ translations.magazines || 'Magazines' }}
                </h2>

                <ul class="search__group-list">
                    <li
                        v-for="result in results.magazines"
                        :key="result.id"
                        class="search__group-list-item search__group-list-item--magazine"
                    >
                        <ds-molecule-magazine-article-card
                            :url="result.url"
                            :description="result.description"
                            title-color="#000616"
                            title-hover-color="#000616"
                        >
                            <template #image="{ classes }">
                                <div :class="classes + '-container'">
                                    <WatsonAtomImage
                                        v-if="result.image.url"
                                        :class="classes"
                                        :content="result.image"
                                    />

                                    <img
                                        v-else-if="page.props.brand.logo"
                                        :class="[
                                            classes,
                                            classes + '--fallback'
                                        ]"
                                        :src="page.props.brand.logo"
                                    >
                                </div>
                            </template>

                            <template #title="{ classes }">
                                <h3
                                    v-if="result.title"
                                    :class="[
                                        classes,
                                        'u-paragraph u-bold'
                                    ]"
                                >
                                    {{ result.title }}
                                </h3>
                            </template>

                            <template #date="{ classes }">
                                <p
                                    v-if="result.date"
                                    :class="[
                                        classes,
                                        'u-tiny-text u-regular'
                                    ]"
                                >
                                    {{ result.date }}
                                </p>
                            </template>
                        </ds-molecule-magazine-article-card>
                    </li>
                </ul>

                <button
                    v-if="canAddResults('magazines')"
                    class="search__group-button u-heading-4 u-bold"
                    @click="addResults('magazines')"
                >
                    {{ translations.loadMore || 'See more results' }}
                </button>
            </div>

            <div
                v-if="results.pages.length"
                class="search__group"
            >
                <h2 class="search__group-title">
                    {{ translations.pages || 'Pages' }}
                </h2>

                <ul class="search__group-list">
                    <li
                        v-for="result in results.pages"
                        :key="result.id"
                        class="search__group-list-item"
                    >
                        <WatsonThemeLinkCard
                            :content="result"
                            :url-text="translations.pagesButtonText || 'Read more'"
                        />
                    </li>
                </ul>

                <button
                    v-if="canAddResults('pages')"
                    class="search__group-button u-heading-4 u-bold"
                    @click="addResults('pages')"
                >
                    {{ translations.loadMore || 'Load More' }}
                </button>
            </div>

            <div
                v-if="!results.priceGuides.length && !results.magazines.length && !results.pages.length"
                class="search__no-results"
            >
                <h2 class="search__no-results-title u-heading-2">
                    {{ translations.noResults || 'No results' }} "{{ query }}"
                </h2>

                <p class="search__no-results-text u-paragraph">
                    {{ translations.noResultsText || 'Try another search' }}
                </p>

                <form
                    class="search__form"
                    @submit.prevent="handleSearch"
                >
                    <input
                        v-model="searchQuery"
                        class="search__form-input"
                        type="text"
                        placeholder="Search"
                    >

                    <button
                        type="submit"
                        class="search__form-submit-button"
                    >
                        <FontAwesomeIcon
                            class="search__form-submit-button-icon"
                            :icon="faMagnifyingGlass"
                        />
                    </button>
                </form>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { usePage } from '@inertiajs/vue3';

import { DsMoleculeMagazineArticleCard } from 'design-system-component-library/marketplaces';

import WatsonThemeLinkCard from '../../components/link-card/watson-theme-link-card.vue';
import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const page = usePage();

const props = defineProps({
    query: {
        type: String,
        required: true,
    },

    priceGuides: {
        type: Object,
        required: true,
    },

    magazines: {
        type: Object,
        required: true,
    },

    pages: {
        type: Object,
        required: true,
    },
});

const translations = page.props.settings.translations?.searchResults || {};

const results = ref({
    priceGuides: props.priceGuides.items,
    magazines: props.magazines.items,
    pages: props.pages.items,
});

const canAddResults = postType => {
    return Math.ceil(results.value[postType].length / 8) < props[postType].maxPages;
};

const addResults = async postType => {
    if (!canAddResults(postType)) return;

    const page = Math.ceil(results.value[postType].length / 8) + 1;

    const response = await fetch('/api/post-type-search', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            search: props.query,
            page,
            per_page: 8,
            post_type: props[postType].postType,
        }),
    }).then(response => response.json());

    results.value[postType] = results.value[postType].concat(response.items);
};

const searchQuery = ref('');

const handleSearch = () => {
    if (searchQuery.value.trim()) {
        window.location.href = `/search?query=${encodeURIComponent(searchQuery.value.trim())}`;
    }
};
</script>

<style lang="scss">
@use 'search-index';
</style>
