<template>
    <header class="header-profile-container">
        <div class="header-profile">
            <div class="header-profile__inner">
                <div class="header-profile__content-left">
                    <WatsonAtomImage
                        v-if="author.picture"
                        class="header-profile__image"
                        :content="author.picture"
                    />

                    <h1
                        v-if="author.name"
                        class="header-profile__name u-heading-3"
                    >
                        {{ author.name }}
                    </h1>

                    <ul
                        v-if="author.socials.length > 0"
                        class="header-profile__socials"
                    >
                        <li
                            v-for="(social, index) in author.socials"
                            :key="index"
                            class="header-profile__social"
                        >
                            <a
                                v-if="social?.platform"
                                class="header-profile__social-link"
                                :href="social.url ? social.url : `mailto:${social.email}`"
                                :target="social.url ? '_blank' : ''"
                                :aria-label="social.platform"
                            >
                                <FontAwesomeIcon
                                    :icon="getSocialIcon(social.platform)"
                                />
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="header-profile__divider">
                </div>

                <div
                    class="header-profile__content-right"
                >
                    <div class="header-profile__job">
                        <p class="header-profile__job-title">
                            {{ author.jobTitle }}
                        </p>

                        <p
                            v-if="author.jobDescription"
                            class="header-profile__job-description u-tiny-text"
                        >
                            {{ author.jobDescription }}
                        </p>
                    </div>

                    <section class="header-profile__expertise">
                        <h2 class="header-profile__expertise-title u-paragraph u-bold">
                            {{ translations.areasOfExpertise }}
                        </h2>

                        <ul class="header-profile__expertise-list">
                            <li
                                v-for="expertise in author.areasOfExpertise"
                                :key="expertise.name"
                                class="header-profile__expertise-item"
                            >
                                {{ expertise.name }}
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    </header>
</template>
<script setup>
import { usePage } from '@inertiajs/vue3';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebookSquare, faInstagramSquare, faLinkedin, faSquareXTwitter, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faSquareEnvelope } from '@fortawesome/pro-regular-svg-icons';

import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';

const page = usePage();
defineProps({
    author: {
        type: Object,
        required: true,
    },
});

const translations = page.props.settings?.translations?.authorPage;

const getSocialIcon = platform => {
    switch (platform) {
        case 'facebook':
            return faFacebookSquare;
        case 'instagram':
            return faInstagramSquare;
        case 'twitter-x':
            return faSquareXTwitter;
        case 'tiktok':
            return faTiktok;
        case 'linkedin':
            return faLinkedin;
        case 'email':
            return faSquareEnvelope;
    }
};
</script>

<style lang="scss">
@use 'watson-theme-header-profile';
</style>
